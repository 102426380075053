import styles from './Home.module.css'

import LeftSideBar from "../components/leftSideBar/leftSideBar";
import Header from "../components/header/header";
import HomeBody from "../components/homeBody/homeBody";
import {useState} from "react";
import {PageNames} from "../common/constants";
import Head from "next/head";

export default function Home() {
  const [sideBarOpen, setSideBarOpen] = useState(true);

  return (
    <>
      <Head>
        <title>Sports Fever - Home</title>
      </Head>
      <div className={styles.HomeMainDiv}>
        <LeftSideBar sideBarOpen={sideBarOpen} setSideBarOpen={() => setSideBarOpen(!sideBarOpen)} pageName={PageNames.home}/>
        <Header setSideBarOpen={() => setSideBarOpen(!sideBarOpen)} sideBarOpen={sideBarOpen}/>
        <HomeBody sideBarOpen={sideBarOpen}/>
      </div>
    </>
  );
}
