import styles from './homeBody.module.css';

interface PropsInterface {
  sideBarOpen: boolean
}

export default function HomeBody(props: PropsInterface) {
  return (
    <div className={styles.HomeBodyMain}>

    </div>
  )
}